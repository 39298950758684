/*@font-face {
    font-family: Campton;    
    src: url('../../comeos/fonts/Campton-Light.eot') format('embedded-opentype');
}

@font-face {
    font-family: Campton;
    font-weight: bold;    
    src: url('../../comeos/fonts/Campton-Bold.eot') format('embedded-opentype');
}*/
/*html{
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
body {
    overflow-x: hidden;
    overflow-y: auto;
}*/
.altai-theme-content #bodyView .comeos-root-container {
  width: 100% !important;
  font-family: Campton;
  padding-bottom: 0;
}
.altai-theme-content #bodyView .comeos-root-container .edit-mode .fixed-panel {
  position: relative;
}
.altai-theme-content #bodyView .comeos-root-container .fixed-panel {
  position: fixed;
  width: 100%;
  z-index: 100;
}
.altai-theme-content #bodyView .comeos-root-container .fixed-panel-margin {
  height: 120px;
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone {
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 150%;
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone .footer-zone-inner {
  padding-left: 50px;
  padding-right: 50px;
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone .footer-share {
  font-size: 0.9em;
}
@media only screen and (min-width: 768px) {
  .altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone {
    text-align: left;
  }
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone-title {
  font-weight: bold;
  margin-bottom: 50px;
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-newsletter-submit {
  border-radius: 17px;
  background-color: #fff;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
  display: block;
}
.altai-theme-content #bodyView .comeos-root-container .footerView .footer-zone-logo {
  max-width: 250px;
  margin: auto;
}
.altai-theme-content #bodyView .comeos-root-container.loggedin.adminmenu {
  margin-top: 44px;
}
